import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { getCurrentRoster } from "../services/manpower.service";
import { parseDateTime, displayUtcDate } from "@/helpers/dateTimeHelpers";

const state = () => ({
  data: {},
});

const getters = {
  isEmpty(state) {
    return get(state, "data.rosterId") !== null;
  },
  currentRosterId(state) {
    return get(state, "data.rosterId");
  },
  currentRosterPlanDateForDisplay: (state) =>
    displayUtcDate(get(state, "data.planDateDt"), "DD MMM"),
  currentRosterPlanDate: (state) => {
    var planDate = get(state, "data.planDate");
    if (isEmpty(planDate)) {
      planDate = new Date();
    }
    return parseDateTime(planDate, false);
  },
  currentRosterShift: (state) => get(state, "data.dwsText"),
  currentStationId: (state, _, rootState) => {
    const _thisStationId = get(state, "data.station.stationId");
    const _selectStationId = get(rootState, "selectedStation.stationId");
    return _thisStationId || _selectStationId;
  },
  currentStationName: (state, _, rootState) => {
    const _thisStationName = get(state, "data.station.stationName")
    const _selectStationName = get(rootState, 'selectedStation.stationName');
    return _thisStationName || _selectStationName;
  },
  currentStationOption: (state, getters) => {
    const stationId = getters['currentStationId'];
    const stationName = getters['currentStationName']
    return stationId ?
    {
      label: stationName,
      value: stationId,
    } : null
  },
  currentLineId: (state) => get(state, "data.line.lineId"),
};

const mutations = {
  setCurrentRoster(state, roster) {
    state.data = {
      ...roster,
    };
  },
  reset(state) {
    state.data = {};
  },
};

const actions = {
  getCurrentRoster: async function({ commit }) {
    const result = await getCurrentRoster();
    if (result.success) {
      commit("setCurrentRoster", get(result, "payload.data", {}));
      return result;
    } else {
      return result;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
