import Dexie from "dexie";
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { isPrivateMode } from "../helpers/browserHelpers"

const DATABASE_NAME = "database";
const TABLE_NAME = "offlineData";

export class Database {
  constructor() {
    this.database = null;
  }

  async init() {
    const isBrowserPrivacyOn = await isPrivateMode();
    if(isBrowserPrivacyOn) {
      Dexie.dependencies.indexedDB = require('fake-indexeddb');
      Dexie.dependencies.IDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange');
    }
    let database = new Dexie(DATABASE_NAME);

    database.version(1).stores({
      [TABLE_NAME]: "++id, value",
    });
    this.database = database;
  }

  async getFirstRecord() {
    const tableArr = await this.database[TABLE_NAME].limit(1).toArray();
    return get(tableArr, '0', null);
  }
  
  async getOfflineData() {
    const record = await this.getFirstRecord();
    const dataStr = get(record, 'value', "");
    try {
      const data = JSON.parse(dataStr);
      return data;
    } catch (e) {
      return {};
    }
  }

  async updateOfflineData(value) {
    const record = await this.getFirstRecord();
    if (isEmpty(record)) {
      await this.database[TABLE_NAME].add({ value: value });
    } else {
      const id = get(record, 'id');
      await this.database[TABLE_NAME].update(id, { value: value });
    }
  }

  clearOfflineData() {
    return this.database[TABLE_NAME].clear();
  }
}
