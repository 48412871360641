import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { getListOfFirstAidBoxes } from "../../services/inventory.service";

const state = () => ({
  list: [],
  isLoading: false,
  error: null,
});

const getters = {
  options: (state) =>
    state.list.map(
      ({
        inventoryFirstAidBoxId,
        name,
        primaryLocationId,
        primaryLocationName,
        secondaryLocationName,
      }) => ({
        label: name,
        value: inventoryFirstAidBoxId,
        primaryLocationId,
        primaryLocationName,
        secondaryLocationName,
      })
    ),
  getById: (state) => (id) =>
    state.list.find(
      ({ inventoryFirstAidBoxId }) => inventoryFirstAidBoxId === id
    ),
};

const mutations = {
  setList(state, list = []) {
    if (!state) state = {};
    state.list = [...list];
  },
  setIsLoading(state, isLoading) {
    if (!state) state = {};
    state.isLoading = isLoading;
  },
  setError(state, error) {
    if (!state) state = {};
    state.error = isEmpty(error) ? null : { ...error };
  },
  reset(state) {
    if (!state) state = {};
    state.list = [];
    state.isLoading = false;
    state.error = null;
  },
};

const actions = {
  load: async ({ commit, rootGetters }) => {
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];
    const lineId = rootGetters["auth/userLineId"];
    const result = await getListOfFirstAidBoxes({
      stationId: stationId,
      lineId: lineId,
    });

    if (result.success) {
      commit("setList", get(result, "payload.list", []));
      commit("setError", null);
    } else {
      commit("setError", result.payload);
    }

    commit("setIsLoading", false);
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
