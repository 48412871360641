import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { OrderStatus } from "../../constants/APIEnums/inventoryEnums";
import { getListOfInventoryOrders } from "../../services/inventory.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

const state = () => ({
  list: [],
  isLoading: false,
  error: null,
});

const getters = {
  orderOptions: (state) => {
    if (get(state, "list.length") > 0)
      return state.list
        .filter(
          ({ statusEnum }) => statusEnum === OrderStatus.PENDING_DELIVERY.VALUE
        )
        .map(({ inventoryOrderId, inventoryOrderNo }) => ({
          value: inventoryOrderId,
          label: inventoryOrderNo,
        }));
    else return [];
  },
  orderDetails: (state) => (id) =>
    state.list.find((o) => o.inventoryOrderId === id),
  orderItems: (state) => (id) => {
    const order = state.list.find((o) => o.inventoryOrderId === id);
    return get(order, "orderItems", []);
  },
};

const mutations = {
  setList(state, list = []) {
    if (!state) state = {};
    state.list = [...list];
  },
  setIsLoading(state, isLoading) {
    if (!state) state = {};
    state.isLoading = isLoading;
  },
  setError(state, error) {
    if (!state) state = {};
    state.error = isEmpty(error) ? null : { ...error };
  },
  reset(state) {
    state.list = [];
    state.isLoading = false;
    state.error = null;
  },
};

const actions = {
  load: async ({ dispatch, rootGetters }) => {
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];
    const lineId = rootGetters["auth/userLineId"];
    const result = await dispatch("getOrderList", {
      lineId,
      stationId,
    });
    return result;
  },
  getOrderList: async (
    { commit },
    {
      stationId = null,
      lineId = null,
      statuses = [],
      inventoryTypeIds = [],
      skip = 0,
      take = 100,
    }
  ) => {
    commit("setIsLoading", true);
    const result = await getListOfInventoryOrders({
      statuses,
      stationId,
      lineId,
      inventoryTypeIds,
      skip,
      take,
    });

    if (result.success) {
      result.payload.list.forEach((item) =>
      {
        item.orderDate = item.orderDate ? displayUtcDate(item.orderDate) : "-";
        item.deliveryDate = item.deliveryDate ? displayUtcDate(item.deliveryDate) : "-";
        item.expectedDeliveryDate = item.expectedDeliveryDate ? displayUtcDate(item.expectedDeliveryDate) : "-";
      });
      commit("setList", get(result, "payload.list", []));
      commit("setError", null);
    } else {
      commit("setError", result.payload);
    }

    commit("setIsLoading", false);
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
