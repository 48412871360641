import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { getListOfInventoryTypes } from "../../services/inventory.service";

const state = () => ({
  list: [],
  isLoading: false,
  error: null,
});

const getters = {
  getById: (state) => (id) =>
    state.list.find(({ inventoryTypeId }) => inventoryTypeId === id),
};

const mutations = {
  setList(state, list = []) {
    state.list = [...list];
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setError(state, error) {
    state.error = isEmpty(error) ? null : { ...error };
  },
  reset(state) {
    state.list = [];
    state.isLoading = false;
    state.error = null;
  },
};

const actions = {
  load: async ({ commit, rootGetters }) => {
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];
    const result = await getListOfInventoryTypes({
      stationId: stationId,
    });

    if (result.success) {
      commit("setList", get(result, "payload.list", []));
      commit("setError", null);
    } else {
      commit("setError", result.payload);
    }

    commit("setIsLoading", false);
    return result;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
