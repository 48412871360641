import inventory from "./inventory.module";
import inventoryType from "./inventoryTypes.module";
import location from "./location.module";
import order from "./order.module";
import firstAidBox from "./firstAidBox.module";

export default {
  namespaced: true,
  modules: {
    location,
    inventoryType,
    inventory,
    order,
    firstAidBox,
  },
  actions: {
    reset({ commit }) {
      commit('location/reset');
      commit('inventoryType/reset');
      commit('inventory/reset');
      commit('order/reset');
      commit('firstAidBox/reset');
    },
    loadInitialData({ dispatch }) {
      dispatch('location/load');
      dispatch('inventoryType/load');
      dispatch('inventory/load');
      dispatch('order/load');
      dispatch('firstAidBox/load');
    },
  }
};