import isEmpty from "lodash.isempty";
import get from "lodash.get";
import { nanoid } from "nanoid";
import { postAsync } from "../services/serviceHandlers";
import {
  USE_ITEMS,
  UPDATE_INVENTORY,
  VERIFY_FIRST_AID_BOX,
  OPEN_SMART_CARD_BOX,
  CREATE_STATION_CASH_COLLECTION_FORM,
  CREATE_STATION_TRANSFER,
  MOVE_CASH_BAGS_AFC,
  MOVE_CASH_BOX,
  USE_COIN_TUBE,
  CREATE_DISCREPANCY_REPORT,
  MOVE_IN_ITEMS,
  CREATE_INVENTORY_TYPE,
} from "../constants/apis";
import { uploadFile } from "@/services/file.service";
import { imageDataToBlob } from "@/helpers/imageDataToBlob";
import { parseDateTime } from "../helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "../constants/APIEnums/attachmentEnums";

const ID_PREFIX = "offline_";

const state = () => ({
  requestData: {},
  requestIdList: [],
});

const getters = {
  hasData: (state) => !isEmpty(state.requestIdList),
  idList: (state) => state.requestIdList,
  dataList: (state) =>
    state.requestIdList.map((id) => ({
      id,
      ...state.requestData[id],
    })),
  pendingSyncLength: (state) =>
    Object.values(state.requestData).filter(({ isSync }) => !isSync).length,
  getRequest: (state) => (id) => get(state.requestData, id, null),
};

const mutations = {
  addPendingSyncData(
    state,
    { url = "", data = {}, isSync = false, error = null }
  ) {
    const newId = ID_PREFIX + nanoid();
    state.requestIdList = [...state.requestIdList, newId];
    state.requestData = {
      ...state.requestData,
      [newId]: {
        url,
        data,
        isSync,
        error,
        createdDate: parseDateTime(new Date()),
      },
    };

    return true;
  },
  updatePendingSyncData(
    state,
    { id, data = {}, isSync = false, error = null }
  ) {
    if (state.requestIdList.includes(id)) {
      state.requestData = {
        ...state.requestData,
        [id]: {
          ...state.requestData[id],
          data,
          isSync,
          error,
        },
      };
      return true;
    }
    return false;
  },
  removeRequests(state, removalList = []) {
    if (removalList.length === 0) return;
    let idList = [
      ...state.requestIdList.filter((id) => !removalList.includes(id)),
    ];

    let newRequestData = {};
    idList.forEach((id) => {
      newRequestData[id] = state.requestData[id];
    });

    state.requestIdList = [...idList];
    state.requestData = { ...newRequestData };
    return;
  },
  reset(state) {
    state.requestIdList = [];
    state.requestData = {};
  },
};

const actions = {
  sendPendingSyncData: async ({ state, commit }, idList = []) => {
    if (isEmpty(idList)) {
      idList = state.requestIdList;
    }
    const len = idList.length;
    for (let i = 0; i < len; i++) {
      const id = idList[i];
      const { url, data, isSync } = state.requestData[id];
      if(!isSync) {
        let result = { success: false };
        switch (url) {
         
          case CREATE_STATION_TRANSFER: {
          const donorSignatureBlob = imageDataToBlob(
            data.donorSignature,
            "donor_signature"
            );
            const recipientSignatureBlob = imageDataToBlob(
              data.recipientSignature,
              "contractor_signature"
              );
            const uploadResult1 = await uploadFile([
              donorSignatureBlob,
              recipientSignatureBlob,
            ],
            ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
          if (!uploadResult1.success) {
            result = uploadResult1;
          } else {
            const stationTransferData = {
              ...data,
              donorSignatureId: get(uploadResult1, "payload.files.0.fileId"),
              recipientSignatureId: get(
                uploadResult1,
                "payload.files.1.fileId"
                ),
              };
              result = await postAsync({
                url,
                body: stationTransferData,
              });
            }
            break;
          }
          case CREATE_STATION_CASH_COLLECTION_FORM: {
            const officerSignatureBlob = imageDataToBlob(
              data.officerSignature,
              "officer_signature"
            );
            const contractorSignatureBlob = imageDataToBlob(
              data.contractorSignature,
              "contractor_signature"
            );
            let fileLists = [];
            if(data.fileList.length > 0  && data.fileList[0].originalName ){
              data.fileList.forEach(el=>{
                fileLists.push(imageDataToBlob(el.base64data, el.originalName))
              })
             
            } 
            const uploadResult2 = await uploadFile([
              officerSignatureBlob,
              contractorSignatureBlob,
              ...fileLists
            ],
            ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        
          if (!uploadResult2.success) {
            result = uploadResult2;
          } else {
            let list = get(uploadResult2, "payload.files");
            let fliterList = list.splice(2);
            let listAtt =  fliterList.length > 0 ? fliterList.map(f => f.fileId) : data.fileList.map(e=> e.fileId)
          
            const sccfData = {
              ...data,
              attachments:data.fileList ? listAtt: data.attachments,
              SMRTSignatureId: get(uploadResult2, "payload.files.0.fileId"),
              contractorSignatureId: get(
                uploadResult2,
                "payload.files.1.fileId"
                ),
              };
              result = await postAsync({
                url,
                body: sccfData,
              });
          }
          break;
          }
        default:
          result = await postAsync({
            url,
            body: data,
          });
          break;
        }

        commit("updatePendingSyncData", {
          id,
          data,
          isSync: result.success,
          error: result.success ? null : result.payload,
        });
        if (!result.success) {
          return;
        }
      }
    }
  },
  removeSyncedRequests({ state, commit }) {
      const syncedIdList = state.requestIdList.filter(
        (id) => state.requestData[id].isSync
        );
    commit("removeRequests", syncedIdList);
  },
  clear({ state, commit }, removalList = []) {
    if (isEmpty(removalList)) {
      removalList = [...state.requestIdList];
    }

    commit("removeRequests", removalList);

    return removalList.length;
  },
  // Cash management
  moveAFCCashBags({ commit }, data) {
    commit("addPendingSyncData", {
      url: MOVE_CASH_BAGS_AFC,
      data,
    });
  },
  useCoinTube({ commit }, data) {
    commit("addPendingSyncData", {
      url: USE_COIN_TUBE,
      data,
    });
  },
  moveCashBoxes({ commit }, data) {
    commit("addPendingSyncData", {
      url: MOVE_CASH_BOX,
      data,
    });
  },
  stationTransfer({ commit }, data) {
    commit("addPendingSyncData", {
      url: CREATE_STATION_TRANSFER,
      data,
    });
  },
  sccf({ commit }, data) {
  
    commit("addPendingSyncData", {
      url: CREATE_STATION_CASH_COLLECTION_FORM,
      data,
    });
  },
  createPSMDiscrepancy({ commit }, data) {
    commit("addPendingSyncData", {
      url: CREATE_DISCREPANCY_REPORT,
      data,
    });
  },
  createAFCDiscrepancy({ commit }, data) {
    commit("addPendingSyncData", {
      url: CREATE_DISCREPANCY_REPORT,
      data,
    });
  },

  // inventory
  updateInventory({ commit }, data) {
    commit("addPendingSyncData", {
      url: UPDATE_INVENTORY,
      data,
    });
  },
  useInventory({ commit }, data) {
    commit("addPendingSyncData", {
      url: USE_ITEMS,
      data,
    });
  },
  verifyFirstAidBox({ commit }, data) {
    commit("addPendingSyncData", {
      url: VERIFY_FIRST_AID_BOX,
      data,
    });
  },
  openSmartCardBox({ commit }, data) {
    commit("addPendingSyncData", {
      url: OPEN_SMART_CARD_BOX,
      data,
    });
  },
  moveInItems({ commit }, data) {
    commit("addPendingSyncData", {
      url: MOVE_IN_ITEMS,
      data,
    });
  },
  createInventoryType({ commit }, data) {
    commit("addPendingSyncData", {
      url: CREATE_INVENTORY_TYPE,
      data,
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
