import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { getInventoryMainPages } from "../../services/inventory.service";
import { InventoryGroups } from "../../constants/APIEnums/inventoryEnums";

const state = () => ({
  smartCardItems: [],
  smartCardActivityLog: [],
  firstAidItems: [],
  firstAidActivityLog: [],
  generalItems: [],
  generalActivityLog: [],
  digitalRecordItems: [],
  digitalRecordItemsActivityLog: [],
  isLoading: false,
  error: null,
});

const getters = {
  allItems: (state) => [
    ...state.smartCardItems,
    ...state.smartCardActivityLog,
    ...state.digitalRecordItems,
    ...state.digitalRecordItemsActivityLog,
    ...state.firstAidItems,
    ...state.firstAidActivityLog,
    ...state.generalItems,
    ...state.generalActivityLog,
  ],
  getById: (_, getters) => (id) =>
    getters.allItems.find(({ inventoryId }) => inventoryId === id),

};

const mutations = {
  setSmartCardItems(state, newList) {
    state.smartCardItems = newList.length ? [...newList] : [];
  },
  setSmartCardActivityLog(state, newList) {
    state.smartCardActivityLog = newList.length ? [...newList] : [];
  },
  setFirstAidItems(state, newList) {
    state.firstAidItems = newList.length ? [...newList] : [];
  },
  setFirstAidActivityLog(state, newList) {
    state.firstAidActivityLog = newList.length ? [...newList] : [];
  },
  setGeneralItems(state, newList) {
    state.generalItems = newList.length ? [...newList] : [];
  },
  setGeneralActivityLog(state, newList) {
    state.generalActivityLog = newList.length ? [...newList] : [];
  },
  setDigitalRecordItems(state, newList) {
    state.digitalRecordItems = newList.length ? [...newList] : [];
  },
  setDigitalRecordActivityLog(state, newList) {
    state.digitalRecordItemsActivityLog = newList.length ? [...newList] : [];
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setError(state, error) {
    state.error = isEmpty(error) ? null : { ...error };
  },
  reset(state) {
    state.smartCardItems = [];
    state.smartCardActivityLog = [];
    state.firstAidItems = [];
    state.firstAidActivityLog = [];
    state.generalItems = [];
    state.generalActivityLog = [];
    state.digitalRecordItems = [];
    state.digitalRecordItemsActivityLog = [];
    state.isLoading = false;
    state.error = null;
  },
};

const actions = {
  load: ({ dispatch }) => {
    dispatch("loadSmartCards");
    dispatch("loadFirstAidItems");
    dispatch("loadGeneralItems");
    dispatch("loadDigitalRecordItems");
  },

  loadSmartCards: async ({ commit, rootGetters }, params = {}) => {
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];

    const lineId = rootGetters["auth/userLineId"];

    params.stationId = stationId;
    params.lineId = lineId;
    params.inventoryGroup = InventoryGroups.SMART_CARD.VALUE;

    const smartCardResult = await getInventoryMainPages(params);

    if (smartCardResult.success) {
      commit(
        "setSmartCardItems",
        get(smartCardResult.payload.data, "inventories", [])
      );
      commit(
        "setSmartCardActivityLog",
        smartCardResult.payload.data?.transactions?.list ?? []
      );
      commit("setError", null);
    } else {
      commit("setError", smartCardResult.payload);
    }

    commit("setIsLoading", false);
    return smartCardResult;
  },

  loadFirstAidItems: async ({ commit, rootGetters }, params = {}) => {
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];

    const lineId = rootGetters["auth/userLineId"];

    params.stationId = stationId;
    params.lineId = lineId;
    params.inventoryGroup = InventoryGroups.FIRST_AID.VALUE;

    const firstAidResult = await getInventoryMainPages(params);

    if (firstAidResult.success) {
      commit(
        "setFirstAidItems",
        get(firstAidResult.payload.data, "inventories", [])
      );
      commit(
        "setFirstAidActivityLog",
        get(firstAidResult.payload.data, "transactions", [])
      );
      commit("setError", null);
    } else {
      commit("setError", firstAidResult.payload);
    }

    commit("setIsLoading", false);
    return firstAidResult;
  },

  loadGeneralItems: async ({ commit, rootGetters }, params = {}) => {
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];

    const lineId = rootGetters["auth/userLineId"];

    params.stationId = stationId;
    params.lineId = lineId;
    params.inventoryGroup = InventoryGroups.GENERAL.VALUE;

    const generalResult = await getInventoryMainPages(params);

    if (generalResult.success) {
      commit(
        "setGeneralItems",
        get(generalResult.payload.data, "inventories", [])
      );
      commit(
        "setGeneralActivityLog",
        get(generalResult.payload.data, "transactions", [])
      );
      commit("setError", null);
    } else {
      commit("setError", generalResult.payload);
    }

    commit("setIsLoading", false);
    return generalResult;
  },

  loadDigitalRecordItems: async ({ commit, rootGetters }, params = {}) => {
    commit("setError", null);
    commit("setIsLoading", true);
    const stationId =
      rootGetters["selectedStation/id"] ||
      rootGetters["currentRoster/currentStationId"] ||
      rootGetters["auth/userStationId"];

    const lineId = rootGetters["auth/userLineId"];

    params.stationId = stationId;
    params.lineId = lineId;
    params.inventoryGroup = InventoryGroups.SERIALISED_TAG.VALUE;

    const digitalRecordResult = await getInventoryMainPages(params);

    if (digitalRecordResult.success) {

      commit(
        "setDigitalRecordItems",
        get(digitalRecordResult.payload.data, "inventories", [])
      );
      commit(
        "setDigitalRecordActivityLog",
        get(digitalRecordResult.payload.data, "transactions", [])
      );
      commit("setError", null);
    } else {
      commit("setError", digitalRecordResult.payload);
    }

    commit("setIsLoading", false);
    return digitalRecordResult;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
