import get from "lodash.get";

const state = () => ({
  officerAcknowledgementReportsFilters: {},
});

const getters = {
  getFilterValues(state) {
    return get(state, "officerAcknowledgementReportsFilters", {});
  },
};

const mutations = {
  setOfficerAcknowledgementReportsFilters(state, values = {}) {
    state.officerAcknowledgementReportsFilters = { ...values };
  },
  reset(state) {
    state.officerAcknowledgementReportsFilters = {};
  },
};

const actions = {
  setOfficerAcknowledgementReportsPageFilters: async (
    { commit },
    params = {}
  ) => {
    commit("setOfficerAcknowledgementReportsFilters", params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
