const state = () => ({
  stationId: null,
  stationName: null,
  lineId: null,
});

const getters = {
  isSelected: (state) => !!state.stationId,
  id: (state) => state.stationId,
  lineId: (state) => state.lineId,
  name: (state) => state.stationName,
};

const mutations = {
  set(state, { stationId, stationName, lineId }) {
    state.stationId = stationId;
    state.stationName = stationName;
    state.lineId = lineId;
  },
  reset(state) {
    state.stationId = null;
    state.stationName = null;
    state.lineId = null;
  },
};

const actions = {
  initData: function({ state, commit, rootGetters }) {
    const hasStationAssigned = state.stationId !== null;
    if (hasStationAssigned) return;

    let stationId = null;
    let stationName = null;
    let lineId = null;

    const isAuthenticated = rootGetters["auth/isAuthenticated"];
    if (isAuthenticated) {
      stationId = rootGetters["auth/userStationId"];
      stationName = rootGetters["auth/userStationName"];
      lineId = rootGetters["auth/userLineId"];
    }

    const hasCurrentRoster = !rootGetters["currentRoster/isEmpty"];
    if (hasCurrentRoster) {
      stationId = rootGetters["currentRoster/currentStationId"];
      stationName = rootGetters["currentRoster/currentStationName"];
      lineId = rootGetters["currentRoster/currentLineId"];
    }

    if (stationId !== null) {
      commit("set", {
        stationId,
        stationName,
        lineId,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
