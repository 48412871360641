import get from "lodash.get";
import { getNotificationList, markReadNotification } from "../services/notification.service";

const state = () => ({
  isLoading: false,
  notifications: {},
  error: null,
  total: 0,
  isMarkingRead: false,
  markReadError: null,
});

const getters = {
  notificationAtId: (state) => id => get(state.notifications, id, null),
  notificationList: (state) => Object.values(state.notifications),
  isLoading: (state) => state.isLoading,
  error: (state) => state.error,
  isMarkingRead: (state) => state.isMarkingRead,
  markReadError: (state) => state.markReadError,
}

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setError(state, error = null) {
    state.error = error;
  },
  setMarkingRead(state, isMarkingRead) {
    state.isMarkingRead = isMarkingRead
  },
  setMarkReadError(state, error = null) {
    state.markReadError = error;
  },
  setAllRead(state) {
    const keys = Object.keys(state.notifications);
    keys.forEach((key) => {
      state.notifications[key].isRead = true;
    });
  },
  setNotifications(state, { notifications = [], total, reset = true }) {
    state.total = total;
    let newData = {};
    notifications.forEach(n => {
      newData[n.notificationId] = n;
    })
    if (reset) {
      state.notifications = { ...newData };
    } else {
      state.notifications = {
        ...state.notifications,
        ...newData
      };
    }
  },
  reset(state) {
    state.isLoading = false;
    state.error = null;
    state.notifications = {};
    state.total = 0;
    state.isMarkingRead = false;
    state.markReadError = null;
  },
}

const actions = {
  refreshNotifications: async function({ commit, rootGetters }) {
    const isLogin = rootGetters['auth/isAuthenticated'];
    if (!isLogin)
      return;
    
    commit('setError', null);
    commit('setLoading', true);
    const result = await getNotificationList();
    if (result.success) {
      commit('setNotifications', {
        notifications: get(result, 'payload.list', []),
        total: get(result, 'payload.total', 0),
        reset: true 
      });
    } else {
      commit('setError', result.payload);
    }
    
    commit('setLoading', false);
  },
  markAllRead: async function({ commit, rootGetters }) {
    const isLogin = rootGetters['auth/isAuthenticated'];
    if (!isLogin)
      return;
    commit('setMarkReadError', null);
    commit('setMarkingRead', true);
    const result = await markReadNotification();
    if (result.success) {
      commit('setAllRead');
    } else {
      commit('setMarkReadError', result.payload);
    }
    
    commit('setMarkingRead', false);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};